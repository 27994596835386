
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpSupplierEntity from "../entity";
export default class ErpSupplierShow extends DefaultController {
    async init() {
        this.entity = "erp/suppliers"
        await super.init();
    }

    protected getEntityData(elem: any) {
        return ErpSupplierEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#editErpSupplierForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpSupplierForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.getEntity()
                    const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editSupplier") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('erp.supplier.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }
}